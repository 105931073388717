<template>
  <div class="main_menu">
    <router-link v-if="isMobileView" :to="{name: 'main-page'}" class="mobile-logo">
      <img :src="$getAsset('/assets/mcr_logo_dude_transparent.png')" />
    </router-link>
    <router-link v-else :to="{name: 'main-page'}" class="logo">
      <img :src="$getAsset('/assets/mcr_logo_white_transparent.png')" />
    </router-link>
    <main-menu-item
      v-for="(item, index) of displayMenuItems"
      :key="index"
      :text="item.text"
      :mobile-text="item.mobileText"
      :sub-menu="item.subMenu"
      :is-user-item="item.isUserItem"
      :separated="item.separated"
      :route="item.route"
      :is-mobile-view="isMobileView"
      :class="item.classes"
    ></main-menu-item>

    <a class="mcr-button red for-individuals-link" href="https://www.mychinaroots.com/" v-if="!userIsLoggedInState"
      ><div class="mcr-button-label">For Individuals</div></a
    >
  </div>
</template>

<script>
import MainMenuItem from '@common/elements/main-menu/main.menu.item';
import {TAB_ID_FULL_TEXT, TAB_ID_INDEXED_RECORDS} from '@common/utils/consts.search';
import {sourceTypeCategories} from '@common/utils/consts.source';
import {mapGetters} from 'vuex';

export default {
  computed: {
    ...mapGetters([
      'windowWidthState',
      'userEmailState',
      'userIsStaffState',
      'userIsLoggedInState',
      'userFirstNameState',
    ]),
    displayMenuItems() {
      return this.menuItems.filter(item => {
        return this.windowWidthState >= (item.minScreen || 0) && this.windowWidthState <= (item.maxScreen || Infinity);
      });
    },
    menuItems() {
      let searchRecordsSubMenu = [
        this.getRouterLinkItem('All Records', 'search-all-records', null, {tab: TAB_ID_INDEXED_RECORDS}),
        this.getRouterLinkItem('Zupus (Family Tree Books)', 'search-all-records', null, {
          tab: TAB_ID_FULL_TEXT,
          category_id: sourceTypeCategories.CATEGORY_ZUPUS,
        }),
        this.getRouterLinkItem('Birth, Marriage & Death', 'search-all-records', null, {
          tab: TAB_ID_INDEXED_RECORDS,
          category_id: sourceTypeCategories.CATEGORY_BIRTH_DEATH,
        }),
        this.getRouterLinkItem('Immigration & Travel', 'search-all-records', null, {
          tab: TAB_ID_INDEXED_RECORDS,
          category_id: sourceTypeCategories.CATEGORY_IMMIGRATION,
        }),
        this.getRouterLinkItem('Census Records', 'search-all-records', null, {
          tab: TAB_ID_INDEXED_RECORDS,
          category_id: sourceTypeCategories.CATEGORY_CENSUS,
        }),
      ];
      let resourcesSubMenu = [
        this.getRouterLinkItem('Chinese Surnames', 'search-tool-surname'),
        this.getRouterLinkItem('Chinese Villages', 'search-tool-village'),
        this.getRouterLinkItem('Overseas Chinese Communities', 'search-overseas'),
        this.getRouterLinkItem('Genealogy Wiki', 'wiki-index'),
        this.getRouterLinkItem('Image to Text', 'image-to-text'),
        this.getRouterLinkItem('Workshops & Events', 'events'),
        this.getRouterLinkItem('How to Log in', 'how-to-login'),
        this.getRouterLinkItem('Support', 'support'),
      ];

      let menu = [
        {text: 'Search', subMenu: searchRecordsSubMenu},
        {text: 'Resources', subMenu: resourcesSubMenu},
      ];
      if (this.userIsLoggedInState) {
        menu.push(this.userMenu);
      }
      return menu;
    },
    isMobileView() {
      return this.windowWidthState <= this.$breakpoints.mainMenu;
    },
    userMenu() {
      return {
        isUserItem: true,
        separated: true,
        subMenu: [{text: this.userFirstNameState, class: 'library-name', icon: 'bank-icon'}],
      };
    },
  },
  methods: {
    getRouterLinkItem(text, routerName, routeParams, routeQuery) {
      return {text: text, route: {name: routerName, params: routeParams || {}, query: routeQuery || {}}};
    },
  },
  components: {
    MainMenuItem,
  },
  name: 'ProductMenu',
};
</script>

<style lang="scss" scoped>
@import '@common/style/menu';
@import '@common/style/mcr-button';
.for-individuals-link {
  margin-right: 20px;
}

.main_menu::v-deep {
  .heading-6.library-name {
    display: flex;
    align-items: center;
    column-gap: 8px;
    padding: 8px 16px 8px 16px;
    text-transform: none;
    font-size: 1em;
    font-weight: 500;
    color: $text-color;

    .material-design-icon.bank-icon {
      background: $neutral-200;
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 99px;
      flex-shrink: 0;
    }
  }
}

@media only screen and (max-width: $main-menu-breakpoint) {
  .main_menu_item.right-side {
    margin-left: auto;
  }
}
</style>
