<template>
  <div id="app" :class="classes">
    <div class="dev-labels">
      <staging-env-label />
    </div>
    <popup-notifications v-if="showNotifications"></popup-notifications>
    <product-main-menu v-if="showMainMenu"></product-main-menu>
    <loading-ip-login v-if="ipLoginLoadingState"></loading-ip-login>
    <router-view v-else :class="pageContentClasses" />
    <mcr-wiki-glossary-container v-if="displayGlossary"></mcr-wiki-glossary-container>
  </div>
</template>

<script>
import PopupNotifications from '@common/elements/notifications/PopupNotifications';
import stagingEnvLabel from '@common/elements/stagingEnvLabel';
import ProductMainMenu from '@library/base/elements/main-menu/ProductMainMenu';
import LoadingIpLogin from '@library/pages/main/LoadingIpLogin';
import 'babel-polyfill';
import {mapGetters} from 'vuex';

const mcrWikiGlossaryContainer = () => import('@common/elements/glossary/mcrWikiGlossaryContainer');

export default {
  name: 'App',
  metaInfo: {
    title: 'My China Roots Library Edition',
    titleTemplate: '%s – My China Roots Library Edition',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content:
          'Give your members access to the My China Roots database direct from your own library or organization. Offer thousands of searchable online Chinese genealogy records, community records and more.',
      },

      //Standard Meta
      {charset: 'utf-8'},
      {name: 'viewport', content: 'width=device-width,initial-scale=1.0'},
    ],
  },
  watch: {
    bodyForbidScrollState(forbidScroll) {
      forbidScroll ? document.body.classList.add('no-scroll') : document.body.classList.remove('no-scroll');
    },
  },
  computed: {
    ...mapGetters(['bodyForbidScrollState', 'ipLoginLoadingState']),
    showNotifications() {
      return !this.$route.meta.hideNotifications;
    },
    showMainMenu() {
      return this.$route.name && !this.$route.meta.hideMainMenu;
    },
    pageContentClasses() {
      let classes = ['page-content'];
      if (this.showMainMenu) {
        classes.push('has-offset-top');
      }
      return classes;
    },
    displayGlossary() {
      return this.$route.name && this.$route.meta.displayGlossary;
    },
    isMobile() {
      return this.$store.getters.windowWidthState <= this.$breakpoints.mobile + 1;
    },
    classes() {
      return {sm_md: this.isMobile, 'menu-shown': this.showMainMenu};
    },
  },
  components: {
    LoadingIpLogin,
    PopupNotifications,
    mcrWikiGlossaryContainer,
    stagingEnvLabel,
    ProductMainMenu,
  },
};
</script>

<style lang="scss">
@import '@common/style/base';
@import '@common/style/typography';

#app {
  min-height: 100svh;
  display: flex;
  flex-direction: column;

  /* Internet explorer fix for footer */
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: block;
  }

  .dev-labels {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 101;
  }

  &.sm_md {
    min-width: auto;
  }

  .page-content {
    flex: 1;
  }

  .page-content.has-offset-top {
    padding-top: $main-menu-height;
  }
  .page-content.has-offset-top.browser-not-supported-offset {
    padding-top: calc(#{$browser-not-supported-height} + #{$main-menu-height});
  }

  @media only screen and (max-width: $main-menu-breakpoint) {
    .page-content.has-offset-top {
      padding-top: $main-menu-height-mobile;
    }
    .page-content.has-offset-top.browser-not-supported-offset {
      padding-top: calc(#{$browser-not-supported-height} + #{$main-menu-height-mobile});
    }
  }
  @media only screen and (max-width: $main-menu-minimum-width) {
    .page-content.has-offset-top {
      padding-top: calc(#{$main-menu-height-mobile} + 60px);
    }
    .page-content.has-offset-top.browser-not-supported-offset {
      padding-top: calc(#{$browser-not-supported-height} + #{$main-menu-height-mobile} + 60px);
    }
  }
}
</style>
